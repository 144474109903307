
import { defineComponent } from 'vue';
import gsap from 'gsap';
export default defineComponent({          
    setup() {
        const navLinks = [
            {id: 0, routeName: "Home", routeText: ".home()", emitter: 'home'},
            {id: 1, routeName: "Home", routeText: ".work()", emitter: 'work'},
            {id: 2, routeName: "Home", routeText: ".about()", emitter: 'about'},
            {id: 3, routeName: "Home", routeText: ".contacts()", emitter: 'contacts'},
            {id: 4, routeName: "Home", routeText: ".curriculumVitae()"},
        ]
        const beforeEnter: any = (el: any) => {
            el.style.opacity = 0;
            el.style.transform = 'translateX(-100px)';
        }
        const enter: any = (el: any, done: any) => {
            gsap.to(el, {
                opacity: 1,
                x: 0,
                duration: 0.2,
                onComplete: done,
                delay: el.dataset.index * 0.05,
            });
        }
        return { navLinks, beforeEnter, enter }
    },
    name: "MobileNav",
    data() {
        return {
            toggle: false,
            newClass: "",
            isActive: "",
            activatedNavbar: false,
        }
    },
    props: {
        textColor: String,
        backgroundColor: String,
    },
    emits: ['home','work','about','contacts', 'toggleTheme', 'activatedNavbar'],
    methods: {
        toggleClass() {
            const body =  document.querySelector("body")!;

            this.toggle = !this.toggle;
            this.toggle ? this.newClass = "toggle" : this.newClass = "";
            this.toggle ? this.isActive = "isActive" : this.isActive = "";
            this.$emit("activatedNavbar", this.activatedNavbar);
            
            if(this.toggle) {
                body.style.overflowY = "hidden";
            }
            else {
                body.style.overflowY = "auto";
            }
        },
    },
});
