import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, TransitionGroup as _TransitionGroup, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mobileNav" }
const _hoisted_2 = ["data-index", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.isActive)
      ? (_openBlock(), _createBlock(_TransitionGroup, {
          key: 0,
          appear: "",
          tag: "ul",
          onBeforeEnter: _ctx.beforeEnter,
          onEnter: _ctx.enter,
          class: _normalizeClass(["navLinks", _ctx.isActive]),
          style: _normalizeStyle({backgroundColor: _ctx.backgroundColor})
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navLinks, (navlink, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: navlink.id,
                "data-index": index,
                onClick: ($event: any) => (this.$emit(navlink.emitter), _ctx.toggleClass())
              }, [
                _createVNode(_component_router_link, {
                  style: _normalizeStyle({color: _ctx.textColor}),
                  to: { name: navlink.routeName }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(navlink.routeText), 1)
                  ]),
                  _: 2
                }, 1032, ["style", "to"])
              ], 8, _hoisted_2))
            }), 128))
          ]),
          _: 1
        }, 8, ["onBeforeEnter", "onEnter", "class", "style"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "burger",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleClass()))
    }, [
      (!_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "burgerText",
            style: _normalizeStyle({color: _ctx.textColor})
          }, "MENU", 4))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["drawing", _ctx.newClass])
      }, [
        _createElementVNode("div", {
          class: "line1",
          style: _normalizeStyle({backgroundColor: _ctx.textColor})
        }, null, 4),
        _createElementVNode("div", {
          class: "line2",
          style: _normalizeStyle({backgroundColor: _ctx.textColor})
        }, null, 4),
        _createElementVNode("div", {
          class: "line3",
          style: _normalizeStyle({backgroundColor: _ctx.textColor})
        }, null, 4)
      ], 2)
    ])
  ]))
}