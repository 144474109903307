
import { defineComponent } from "vue";
export default defineComponent({
  name: "Navbar",
  props: {
    isDarkMode: Boolean,
    textColor: String,
  },
  emits: ["home", "work", "about", "contacts", "toggleTheme"],
  methods: {
    getImgURL(image: string) {
      return require("../assets/" + image);
    },
    getPdfURL(fileName: string) {
      return require("@/assets/" + fileName).default;
    },
  },
});
