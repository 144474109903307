
import { defineComponent } from "vue";
import Navbar from "../components/Navbar.vue";
import Hero from "../components/Hero.vue";
import SideProject from "../components/SideProject.vue";
import NavbarMobile from "../components/NavbarMobile.vue";
import ScrollToTopButton from "../components/ScrollToTopButton.vue";
import Button from "../components/Button.vue";
import gsap from "gsap";

export default defineComponent({
  name: "Home",
  setup() {
    const chars = [
      { id: 0, text: "T" },
      { id: 1, text: "i" },
      { id: 2, text: "a" },
      { id: 3, text: "g" },
      { id: 4, text: "o" },
    ];
    const chars2 = [
      { id: 5, text: "C" },
      { id: 6, text: "o" },
      { id: 7, text: "s" },
      { id: 8, text: "t" },
      { id: 9, text: "a" },
    ];
    const beforeEnter: any = (el: any) => {
      el.style.opacity = 0;
      el.style.transform =
        "rotate(-5deg) scale(1.1, 1.1) translateX(-10px) translateY(-10px)";
    };
    const enter: any = (el: any, done: any) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        y: 0,
        rotation: 0,
        duration: 1,
        scale: 1,
        ease: "power3",
        onComplete: done,
        delay: el.dataset.index * 0.1,
      });
    };
    return { chars, chars2, beforeEnter, enter };
  },
  data() {
    return {
      sideprojects: [
        {
          id: "1",
          title: "Kucomcu.io Smart Contract",
          description: "The smart contract implementation for Kucomcu.io",
          websiteURL:
            "https://ropsten.etherscan.io/address/0x655B3E27cE170F8E30010E3dfa7AA43B1E7e8B72",
          codeURL: "https://github.com/MrValraven/kucomcu-smart_contract",
        },
        {
          id: "2",
          title: "AAUE.pt Backend (WiP)",
          description:
            "Backend with User auth (JWT), MongoDB integration with user and news models",
          websiteURL: "https://aaue.pt/noticias",
          codeURL: "https://github.com/MrValraven/blog-API",
        },
        {
          id: "2.2",
          title: "BottleCellar.io",
          description:
            "A small project I did for a React + Redux Frontend Developer assessment test",
          websiteURL: "https://bottlecellar.vercel.app",
          codeURL: "https://github.com/MrValraven/bottlecellar",
        },
        {
          id: "2.5",
          title: "Crown Clothing (WiP)",
          description:
            "A fictional clothing Ecommerce, built this to learn React, React Router, Redux, Hooks, Firebase and Stripe API",
          websiteURL: "https://crownclothingecom.vercel.app",
          codeURL: "https://github.com/MrValraven/clothing-ecommerce",
        },
        {
          id: "3",
          title: "Web Scraper",
          description:
            "Web Scraper that gets all the housing listings from alojamento.aaue.pt",
          websiteURL:
            "https://dados-portal-alojamento.herokuapp.com/getAnuncios",
          codeURL: "https://github.com/MrValraven/alojamento-web-scraper",
        },
        {
          id: "4.5",
          title: "Sapatuna - Party Game (WiP)",
          description: "A LGBTQI+ friendly truth or dare game",
          websiteURL: "https://sapatuna.vercel.app/",
          codeURL: "https://github.com/MrValraven/sapatuna",
        },
        {
          id: "4",
          title: "NEEI.UE (WiP)",
          description:
            "A website for the University of Évora's Computer Science Club",
          websiteURL: "https://neei.vercel.app/",
          codeURL: "https://github.com/MrValraven/neei",
        },
        {
          id: "4.1",
          title: "Lost World (WiP)",
          description: "A E-commerce website for the Lost World store in Sines",
          websiteURL: "https://lostworldcomics.netlify.app/",
          codeURL: "https://github.com/MrValraven/lostworld",
        },
        {
          id: "4.5",
          title: "Splitter",
          description:
            "A Tip calculator app built with Vue.js. This is a challenge from Frontend Mentor.",
          websiteURL: "https://splitterapp.netlify.app/#/",
          codeURL: "https://github.com/MrValraven/spliter",
        },
        {
          id: "5",
          title: "Quote Generator",
          description: "A quote generator",
          websiteURL: "https://mrvalraven-quote-generator.netlify.app/",
          codeURL: "https://github.com/MrValraven/Quote-generator",
        },
        {
          id: "6",
          title: "Infinite Scroller",
          description: "Infinite scroller using the Unsplash API",
          websiteURL: "https://mrvalraven-infinite-scroller.netlify.app/",
          codeURL: "https://github.com/MrValraven/Infinite-Scroll",
        },
        {
          id: "7",
          title: "Picture-in-Picture",
          description:
            "A simple project to try out the picture-in-picture feature",
          websiteURL: "https://mrvalraven-pictureinpicture.netlify.app/",
          codeURL: "https://github.com/MrValraven/Picture-in-Picture",
        },
        {
          id: "8",
          title: "Reddit CLI",
          description:
            "A simple reddit CLI that opens a random post from reddit's front page",
          websiteURL: "",
          codeURL: "https://github.com/MrValraven/reddit-cli",
        },
        {
          id: "9",
          title: "Perspective Playground",
          description:
            "A perspective playground to test how Vue's v-model works",
          websiteURL: "https://perspectiveplayground.netlify.app/",
          codeURL: "https://github.com/MrValraven/perspective-playground",
        },
      ],
      currentSideprojectsLength: 6,
      activatedNavbar: false,
      isAtTop: true,
      mobileMode: false,
      finished: false,
      isOnTeto: false,
      isOnKucomcu: false,
      isOnAAUE: false,
      isOnReitor: false,
      isOnExpo: false,
      opacityTeto: 0,
      opacityKucomcu: 0,
      opacityAAUE: 0,
      opacityReitor: 0,
      opacityExpo: 0,
      activateTyping: true,
      codeEnd: false,
      lineNumbers: [121],
      image:
        "https://i.pinimg.com/originals/e3/66/87/e366871039caf5afd17b0bcfecb453cb.jpg",
      contactName: "",
      contactEmail: "",
      contactMessage: "",
      weekDayOfToday: "",
      openNotification: false,
      isDarkMode: false,
      theme: "light",
      whiteBlue: "#E7ECEF",
      darkBlue: "#274C77",
      normalBlue: "#6aa7cf",
      lightBlue: "#A3CEF1",
      editorBlue: "#21313c",
      gostoDesteAzul: "#aedbf7",
      white: "#FFFFFF",
      sendingButton: "Send message",
    };
  },
  components: {
    Navbar,
    Hero,
    NavbarMobile,
    ScrollToTopButton,
    Button,
    SideProject,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    const currentTheme = localStorage.getItem("theme")!;
    this.checkTheme(currentTheme);
    for (let i: number = 122; i < 147; i++) {
      this.lineNumbers.push(i);
    }

    let day = new Date();
    let weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    this.weekDayOfToday = weekday[day.getDay()];
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    showAllProjects() {
      this.currentSideprojectsLength = this.sideprojects.length;
    },
    getImgURL(image: String) {
      return require("../assets/" + image);
    },
    scrollToElement(destination: string) {
      const element = document.querySelector(destination);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScroll() {
      window.pageYOffset >= 250
        ? (this.isAtTop = false)
        : (this.isAtTop = true);

      let tetoProjectHeight = document
        .querySelector(".seistetos")
        ?.getBoundingClientRect().top;
      tetoProjectHeight = Number(tetoProjectHeight);

      if (window.pageYOffset >= tetoProjectHeight - 400) {
        this.isOnTeto = true;
        this.opacityTeto = 1;
      }

      let kucomcuProjectHeight = document
        .querySelector(".kucomcu")
        ?.getBoundingClientRect().top;
      kucomcuProjectHeight = Number(kucomcuProjectHeight);

      if (window.pageYOffset >= kucomcuProjectHeight + 1000) {
        this.isOnKucomcu = true;
        this.opacityKucomcu = 1;
      }
      let aaueProjectHeight = document
        .querySelector(".aaue")
        ?.getBoundingClientRect().top;
      aaueProjectHeight = Number(aaueProjectHeight);

      if (window.pageYOffset >= aaueProjectHeight + 200) {
        this.isOnAAUE = true;
        this.opacityAAUE = 1;
      }
      let reitorProjectHeight = document
        .querySelector(".reitor")
        ?.getBoundingClientRect().top;
      reitorProjectHeight = Number(reitorProjectHeight);

      if (window.pageYOffset >= reitorProjectHeight + 200) {
        this.isOnReitor = true;
        this.opacityReitor = 1;
      }
      let expoProjectHeight = document
        .querySelector(".expoestudante")
        ?.getBoundingClientRect().top;
      expoProjectHeight = Number(expoProjectHeight);

      if (window.pageYOffset >= expoProjectHeight + 2000) {
        this.isOnExpo = true;
        this.opacityExpo = 1;
      }

      let aboutMeHeight = document
        .querySelector(".aboutMe")
        ?.getBoundingClientRect().top;
      aboutMeHeight = Number(aboutMeHeight);

      if (aboutMeHeight < 650 && this.activateTyping) {
        this.typeAllCode();
        this.activateTyping = false;
      }
    },
    handleResize() {
      this.mobileMode = window.innerWidth <= 1015;
      if (!this.mobileMode) {
        this.activatedNavbar = false;
      }
    },

    async sleepNow(delay: number) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    },

    async typeCode(element: string, word: string) {
      let selectedElement = document.querySelector(element)!;

      let index = 0;
      let maxIndex = word.length;
      let letter = "";

      while (index <= maxIndex) {
        await this.sleepNow(20);
        letter = word.slice(0, index);
        selectedElement.textContent = letter;
        index++;
      }
    },

    async typeAllCode() {
      let delay = 0;

      let arrayOfElements = [
        { element: ".const", text: "const" },
        { element: "#me", text: " me" },
        { element: "#igual1", text: " = " },
        { element: "#chavetas1", text: "{" },
        { element: "#nome", text: "name" },
        { element: "#doisPontos1", text: ": " },
        { element: "#Tiago", text: '"Tiago"' },
        { element: "#virgula1", text: "," },
        { element: "#age", text: "favoriteFandom" },
        { element: "#doisPontos2", text: ": " },
        { element: "#idade", text: '"Star Wars"' },
        { element: "#virgula2", text: "," },
        { element: "#role", text: "role" },
        { element: "#doisPontos3", text: ": " },
        { element: "#fullstack", text: '"Fullstack Developer"' },
        { element: "#virgula3", text: "," },
        { element: "#git", text: "github" },
        { element: "#doisPontos4", text: ": " },
        { element: "#githubLink", text: '"https://github.com/MrValraven"' },
        { element: "#virgula4", text: "," },
        { element: "#IDE", text: "IDE" },
        { element: "#doisPontos5", text: ": " },
        { element: "#vscode", text: '"Visual Studio Code"' },
        { element: "#chavetas2", text: "}" },
        { element: "#class", text: "class" },
        { element: "#toolset", text: " Toolset" },
        { element: "#chavetas3", text: " {" },
        { element: "#skills", text: "skills" },
        { element: "#doisPontos6", text: ": " },
        { element: "#chavetas4", text: " {" },
        { element: "#icon1", text: " icon" },
        { element: "#doisPontos7", text: ": " },
        { element: "#string1", text: "string" },
        { element: "#virgula4", text: "," },
        { element: "#name2", text: " name" },
        { element: "#doisPontos8", text: ": " },
        { element: "#string2", text: "string" },
        { element: "#chavetas5", text: " }" },
        { element: "#array1", text: "[" },
        { element: "#array2", text: "]" },
        { element: "#pontovirgula1", text: ";" },
        { element: "#constructor", text: "constructor" },
        { element: "#parenteses1", text: "(" },
        { element: "#skillset", text: "skillset" },
        { element: "#doisPontos9", text: ":" },
        { element: "#chavetas6", text: " {" },
        { element: "#icon2", text: " icon" },
        { element: "#doisPontos10", text: ": " },
        { element: "#string3", text: "string" },
        { element: "#virgula5", text: "," },
        { element: "#name3", text: " name" },
        { element: "#doisPontos11", text: ": " },
        { element: "#string4", text: "string" },
        { element: "#chavetas7", text: " }" },
        { element: "#array3", text: "[" },
        { element: "#array4", text: "]" },
        { element: "#parenteses2", text: ")" },
        { element: "#chavetas8", text: " {" },
        { element: "#this1", text: " this" },
        { element: "#ponto1", text: "." },
        { element: "#skills2", text: "skills" },
        { element: "#ponto2", text: "." },
        { element: "#push", text: "push" },
        { element: "#parenteses3", text: "(" },
        { element: "#reticencias", text: "..." },
        { element: "#skillset2", text: "skillset" },
        { element: "#parenteses4", text: ")" },
        { element: "#pontovirgula2", text: ";" },
        { element: "#chavetas9", text: " {" },
        { element: "#display", text: "display" },
        { element: "#parenteses5", text: "(" },
        { element: "#parenteses6", text: ")" },
        { element: "#chavetas10", text: " {" },
        { element: "#for", text: "for" },
        { element: "#parenteses7", text: "(" },
        { element: "#let", text: "let " },
        { element: "#skill1", text: "skill " },
        { element: "#in", text: "in " },
        { element: "#this2", text: "this" },
        { element: "#ponto3", text: "." },
        { element: "#skills3", text: "skills" },
        { element: "#parenteses8", text: ")" },
        { element: "#chavetas11", text: " {" },
        { element: "#console", text: "console" },
        { element: "#ponto4", text: "." },
        { element: "#log", text: "log" },
        { element: "#parenteses9", text: "(" },
        { element: "#skill2", text: "skill" },
        { element: "#parenteses20", text: ")" },
        { element: "#pontovirgula3", text: ";" },
        { element: "#chavetas12", text: "}" },
        { element: "#chavetas13", text: "}" },
        { element: "#chavetas14", text: "}" },
        { element: "#export", text: "export " },
        { element: "#chavetas15", text: "{" },
        { element: "#toolset2", text: " Toolset " },
        { element: "#chavetas16", text: "}" },
        { element: "#pontovirgula4", text: ";" },
        { element: "#import", text: "import" },
        { element: "#chavetas17", text: " { " },
        { element: "#toolset4", text: "Toolset" },
        { element: "#chavetas18", text: " } " },
        { element: "#from", text: "from " },
        { element: "#home", text: '"./Home.vue"' },
        { element: "#pontovirgula5", text: ";" },
        { element: "#const2", text: "const " },
        { element: "#toolset5", text: "toolset " },
        { element: "#igual2", text: "= " },
        { element: "#new", text: "new " },
        { element: "#toolset6", text: "Toolset" },
        { element: "#parenteses21", text: "(" },
        { element: "#currentSkills", text: "currentSkills" },
        { element: "#parenteses22", text: ")" },
        { element: "#pontovirgula6", text: ";" },
        { element: "#toolsetFinal", text: "toolset" },
        { element: "#ponto5", text: "." },
        { element: "#display2", text: "display()" },
        { element: "#pontovirgula7", text: ";" },
      ];

      for (let i = 0; i < arrayOfElements.length; i++) {
        await this.typeCode(
          arrayOfElements[i].element,
          arrayOfElements[i].text
        );

        if (i % 4 === 0) {
          await this.sleepNow(delay);
        }
      }

      await this.sleepNow(delay);
      this.codeEnd = true;
      await this.typeCode(
        ".lastp",
        "PS C:\\Users\\tiagoCosta\\Desktop\\Trabalhos\\portefolio>"
      );
    },
    async copyDiscord() {
      let copyText = document.getElementById("discordID")!;

      let input = document.createElement("textarea");

      input.value = copyText.textContent!;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");

      input.remove();

      this.openNotification = true;

      setTimeout(() => {
        this.openNotification = false;
      }, 10000);
    },
    checkTheme(theme: string) {
      if (theme === null) {
        this.theme = "light";
      } else {
        this.theme = theme;
      }

      if (this.theme === "light") {
        this.whiteBlue = "#E7ECEF";
        this.darkBlue = "#274C77";
        this.normalBlue = "#6aa7cf";
        this.lightBlue = "#A3CEF1";
        this.editorBlue = "#21313c";
        this.gostoDesteAzul = "#aedbf7";
        this.white = "#FFFFFF";
        this.isDarkMode = false;
      } else {
        this.whiteBlue = "#0e1720";
        this.darkBlue = "#E7ECEF";
        this.normalBlue = "#E7ECEF";
        this.white = "#274C77";
        this.isDarkMode = true;
      }
    },
    toggleDarkMode() {
      this.theme === "light" ? (this.theme = "dark") : (this.theme = "light");

      this.checkTheme(this.theme);

      localStorage.setItem("theme", this.theme);
    },
    sendEmail() {
      if (
        this.contactName === "" ||
        this.contactMessage === "" ||
        this.contactEmail === ""
      ) {
        return;
      }

      this.sendingButton = "Sending message...";

      const message = {
        to: "akatiago@gmail.com",
        from: "akatiago@gmail.com",
        subject: this.contactName,
        text: this.contactMessage,
        html: this.contactMessage + " " + this.contactEmail,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(message),
      };
      fetch(
        "https://send-personal-email-api.herokuapp.com/sendemail",
        requestOptions
      )
        .then((response) => response.json())
        .then(() => {
          this.contactEmail = "";
          this.contactName = "";
          this.contactMessage = "";
          this.sendingButton = "Send message";
        })
        .catch((err) => {
          console.log(err);
          this.sendingButton = "An error has occurred";
          setTimeout(() => {
            this.contactEmail = "";
            this.contactName = "";
            this.contactMessage = "";
            this.sendingButton = "Send message";
          }, 5000);
        });
    },
  },
});
